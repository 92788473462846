import React, { useState } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Text } from "../Core"

const AnchorLinksBar = ({ links, rootUrl }) => {
  if (!links) return <></>
  const [activeHash, setActiveHash] = useState(links[0].id)

  return (
    <div className="anchor-bar">
      {links.map(link => (
        <AnchorLink
          onAnchorLinkClick={() => setActiveHash(link.id)}
          className={`anchor-bar__link ${
            activeHash === link.id ? "active" : ""
          }`}
          key={link.id}
          to={`/${rootUrl}/#${link.id}`}>
          <Text as="span" text={link.text} />
        </AnchorLink>
      ))}
    </div>
  )
}

export default AnchorLinksBar
