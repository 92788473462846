import React from "react"
import classNames from "classnames"
import { Image } from "../Core"

const FullImageText = ({
  image,
  children,
  colorBack = true,
  reverse,
  className
}) => {
  const mainClasses = classNames("full-img-txt", "relative", className, {
    "color-back": colorBack,
    reverse
  })
  return (
    <div className={mainClasses}>
      <div className="full-img-txt__content">{children}</div>
      <Image publicId={image} />
    </div>
  )
}

export default FullImageText
